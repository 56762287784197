var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "side-bar" },
    [
      _vm._l(_vm.sections, function (section, index) {
        return _c(
          "div",
          { key: index, staticClass: "side-bar-section" },
          [
            _c(
              "div",
              {
                staticClass: "side-bar-item-container",
                on: {
                  click: function ($event) {
                    return _vm.openSection(index)
                  },
                },
              },
              [
                _c("SideBarItem", {
                  staticClass: "item",
                  attrs: { icon: section.icon, title: section.name },
                }),
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "slide" } },
              [
                _vm.isOpen[index]
                  ? _c("SideBarSection", {
                      attrs: { items: _vm.filterTiles(section.items) },
                      on: { "launch-app": _vm.launchApp },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "switch-view-buttons" },
        [
          _c(
            "router-link",
            { attrs: { to: "/home/" } },
            [
              _c("IconHome", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t("alternate-views.default"),
                    expression: "$t('alternate-views.default')",
                  },
                ],
                staticClass: "view-icon",
              }),
            ],
            1
          ),
          _c(
            "router-link",
            { attrs: { to: "/minimal/" } },
            [
              _c("IconMinimalView", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t("alternate-views.minimal"),
                    expression: "$t('alternate-views.minimal')",
                  },
                ],
                staticClass: "view-icon",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }