var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "work-space" },
    [
      _c("SideBar", {
        attrs: { sections: _vm.sections, initUrl: _vm.getInitialUrl() },
        on: { "launch-app": _vm.launchApp, "launch-widget": _vm.launchWidget },
      }),
      !_vm.isMultiTaskingEnabled
        ? _c("WebContent", { attrs: { url: _vm.url } })
        : _c("MultiTaskingWebComtent", { attrs: { url: _vm.url } }),
      _vm.widgets
        ? _c("WidgetView", { attrs: { widgets: _vm.widgets } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }