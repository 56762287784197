var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltip,
          expression: "tooltip",
        },
      ],
      class: `side-bar-item ${_vm.icon ? "w-icon" : "text-only"}`,
      on: {
        click: function ($event) {
          return _vm.itemClicked()
        },
      },
    },
    [
      _vm.icon
        ? _c("Icon", { attrs: { icon: _vm.icon, size: "small", url: _vm.url } })
        : _c("p", { staticClass: "small-title" }, [_vm._v(_vm._s(_vm.title))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }