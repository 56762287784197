var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.widgets
    ? _c(
        "div",
        { staticClass: "workspace-widget-view" },
        _vm._l(_vm.widgets, function (widget, widgetIndx) {
          return _c("WidgetBase", {
            key: widgetIndx,
            staticClass: "workspace-widget",
            attrs: { widget: widget, index: widgetIndx },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }