var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sub-side-bar" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          !item.subItems
            ? _c("SideBarItem", {
                staticClass: "item",
                attrs: {
                  icon: item.icon,
                  title: item.title,
                  url: item.url,
                  target: item.target,
                },
                on: { "launch-app": _vm.launchApp },
              })
            : _vm._e(),
          item.subItems
            ? _c(
                "div",
                { staticClass: "sub-item-group" },
                _vm._l(item.subItems, function (subItem, subIndex) {
                  return _c("SideBarItem", {
                    key: subIndex,
                    staticClass: "item sub-item",
                    attrs: {
                      icon: subItem.icon,
                      title: subItem.title,
                      url: subItem.url,
                      target: subItem.target,
                    },
                    on: { "launch-app": _vm.launchApp },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }